<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toUrl('add_shoplist', false)">基本信息</span>
      <span @click="toUrl('add_shoplist_js')">结算信息</span>
      <span @click="toUrl('add_shoplist_pz')">配置信息</span>
      <span id="fzcolor">受益人信息</span>
    </div>
    <div class="header" v-else>
      <span @click="toUrl('add_shoplist', false)">基本信息</span>
      <span @click="toUrl('add_shoplist_js')">结算信息</span>
      <span @click="toUrl('add_shoplist_pz')">配置信息</span>
      <span id="fzcolor">受益人信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">受益人信息</h5>
    </div>

    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 100px">
            <p>受益人名称</p>
          </td>
          <td style="width: 100px">
            <p>受益人证件类型</p>
          </td>
          <td style="width: 100px">
            <p>受益人证件号</p>
          </td>
          <td style="width: 100px">
            <p>证件有效期</p>
          </td>
          <td style="width: 100px">
            <p>受益人证件正面照片</p>
          </td>
          <td style="width: 100px">
            <p>受益人证件反面照片</p>
          </td>
          <td style="width: 100px">
            <p>受益人居住地</p>
          </td>
          <td style="width: 100px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td style="width: 100px">
            <p>{{ v.beneName }}</p>
          </td>
          <td style="width: 100px">
            <p v-show="v.beneType == '01'">身份证</p>
            <p v-show="v.beneType == '04'">外国护照</p>
            <p v-show="v.beneType == '09'">中国香港居民-来往内地通行证</p>
            <p v-show="v.beneType == '10'">中国澳门居民-来往内地通行证</p>
            <p v-show="v.beneType == '11'">中国台湾居民-来往大陆通行证</p>
          </td>
          <td style="width: 100px">
            <p>{{ v.beneIdNum }}</p>
          </td>
          <td style="width: 100px">
            <p>{{ v.beneValidDateStart }}至 {{ v.beneValidDateEnd == "forever" ? "长期"  : v.beneValidDateEnd }}</p>
          </td>
          <td style="width: 100px">
            <p>
                <el-button
                  @click="showImg(v.beneIdNomalPic)"
                  type="text"
                  size="medium"
                  >点击预览</el-button
                >
            </p>
          </td>
          <td style="width: 100px">
             <p>
                <el-button
                  @click="showImg(v.beneIdReversePic)"
                  type="text"
                  size="medium"
                  >点击预览</el-button
                >
            </p>
          </td>
          <td style="width: 100px">
             <p>{{ v.beneAddress }}</p>
          </td>
          <td style="width: 100px">
            <p>
              <i class="lianjie" @click="editTab(v, i)">编辑</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addTab">
      <span>+</span> 新增受益人信息
    </h6>

    <div class="btn">
      <el-button @click="toUrl('add_shoplist_pz', false)" class="back"
        >上一步</el-button
      >
      <el-button
        @click="submit"
        :disabled="buttonFlag"
        class="next"
        type="primary"
        >完成</el-button
      >
    </div>

    <!-- 预览 -->
    <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
      <a :href="showImgUrl" target="_blank">
        <img :src="showImgUrl" alt="加载失败" />
      </a>
    </div>

    <!-- 新增 -->
    <el-dialog title="受益人信息" :visible.sync="overAccountShow" width="600px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>受益人名称</div>
          <el-input
            v-model="formData.beneName"
            maxlength="128"
            placeholder="输入受益人名称"
            tabindex="2"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>受益人证件号</div>
          <el-input
            v-model="formData.beneIdNum"
            maxlength="128"
            placeholder="输入受益人证件号"
            tabindex="3"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>受益人居住地</div>
          <el-input
            v-model="formData.beneAddress"
            maxlength="128"
            placeholder="输入受益人居住地"
            tabindex="3"
          ></el-input>
          <div class="uploader1">
            <div class="overAccount_ul_title"><i>*</i>受益人证件正面照片</div>
            <el-upload
              class="avatar-uploader"
              :before-upload="beforeUpload"
              drag
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="contactIdNomalPicSuccess"
            >
              <img
                v-if="formData.beneIdNomalPic"
                :src="formData.beneIdNomalPic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span v-if="formData.beneIdNomalPic" class="avatar-uploader-span" @click.stop="formData.beneIdNomalPic = ''"><i class="el-icon-close"></i></span>
            </el-upload>
          </div>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>受益人证件类型</div>
          <el-select
            v-model="formData.beneType"
            placeholder="选择受益人证件类型"
            tabindex="1"
          >
            <el-option
              v-for="(v, i) in legalPersonCemType"
              :key="i"
              :label="v"
              :value="i"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>证件有效期</div>
          <el-date-picker
            class="inputs pick"
            v-model="formData.beneValidDateStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="开始日期"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px;height: 36px;line-height: 36px;">至</span>
          <el-date-picker
            class="inputs pick"
            v-model="formData.beneValidDateEnd"
            :picker-options="beneValidDateEndFV"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="结束日期"
          >
          </el-date-picker>
          <div class="uploader1">
            <div class="overAccount_ul_title"><i>*</i>受益人证件反面照片</div>
            <el-upload
              class="avatar-uploader"
              :before-upload="beforeUpload"
              drag
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="contactIdReversePicSuccess"
            >
              <img
                v-if="formData.beneIdReversePic"
                :src="formData.beneIdReversePic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span v-if="formData.beneIdReversePic" class="avatar-uploader-span" @click.stop="formData.beneIdReversePic = ''"><i class="el-icon-close"></i></span>
            </el-upload>
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="overAccountShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" :disabled="buttonFlag" @click="sureAdd"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { URL_API } from "@/utils/apiUrl.js";
import { addApi } from "@/api/user/shopList.js";
import * as imageConversion from "image-conversion";
var beneValidDateEnd = null;
export default {
  data() {
    return {
      overAccountShow: false, 
      formData: { 
        beneName: "", //受益人名称
        beneIdNum: "", //受益人身份证号	
        beneAddress:"", //受益人地址
        beneType: "", //受益人证件类型
        beneIdNomalPic:"", //受益人证件正面照片
        beneIdReversePic:"",//受益人证件反面照片
        beneValidDateStart: "", //true	证件开始日期，格式yyyy-MM-dd
        beneValidDateEnd: "", //true 证件结束日期，格式yyyy-MM-dd，可选特殊值forever表示长期
      },
      beneValidDateEndFV: {
        shortcuts: [
          {
            text: "长期",
            onClick(picker) {
              picker.$emit("pick", "2999-01-01");
              beneValidDateEnd = "forever";
            },
          },
        ],
      },
      legalPersonCemType: {
        "01": "身份证",
        "04": "外国护照",
        "09": "中国香港居民-来往内地通行证",
        10: "中国澳门居民-来往内地通行证",
        11: "中国台湾居民-来往大陆通行证",
      },
      tabData: [],
      editIndex: "",
      pageType: "", //页面状态
      buttonFlag: false, //防止重复点击
      uploadUrl: URL_API.upload,
      showImgUrl:"",
    };
  },
  computed: {
    ...mapState({
      storesApiDetail: "user_storesApiDetail",
    }),
  },
  created() {
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    } else {
      if (this.storesApiDetail) {
        console.log("this.storesApiDetail",this.storesApiDetail)
        if (this.storesApiDetail.beneInfos) {
          this.tabData = this.storesApiDetail.beneInfos;
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setStoresApi: "user_setStoresApi",
    }),
    submit() {
      let data = {
        storeDetail: this.storesApiDetail ? this.storesApiDetail.storeDetail : null,
        account: this.storesApiDetail ? this.storesApiDetail.account : null,
        config:  this.storesApiDetail ? this.storesApiDetail.config : null,
        beneInfos: this.tabData, 
      };
      this.setStoresApi(data);
      this.buttonFlag = true;
      addApi(this.storesApiDetail).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.setStoresApi(null);
          this.$router.push({
            name: "shoplist_mdlb", 
          });
        }else{
          this.buttonFlag = false;
        }
        
      });
    },
    //获取详情
    getDetail() {
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.tabData = res.resultData.beneInfos;
        }
      });
    },
    // 添加
    addTab() {
      if(this.tabData.length == 4){
          this.$message.success("最多只能新增4条受益人信息");
          return false;
      }
      this.formData = {
        beneName: "", //受益人名称
        beneIdNum: "", //受益人身份证号	
        beneAddress:"", //受益人地址
        beneType: "", //受益人证件类型
        beneIdNomalPic:"", //受益人证件正面照片
        beneIdReversePic:"",//受益人证件反面照片
        beneValidDateStart: "", //true	证件开始日期，格式yyyy-MM-dd
        beneValidDateEnd: "", //true 证件结束日期，格式yyyy-MM-dd，可选特殊值forever表示长期
      };
      this.editIndex = null;
      this.buttonFlag = false;
      this.overAccountShow = true;
    },
    // 修改
    editTab(formData, index) {
      this.formData = JSON.parse(JSON.stringify(formData));
      if (this.formData.beneValidDateEnd == "forever") {
        beneValidDateEnd = "forever";
        this.formData.beneValidDateEnd = "2999-01-01";
      }
      this.editIndex = index;
      this.buttonFlag = false;
      this.overAccountShow = true;
    },
    // 确认添加
    sureAdd() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.beneName) {
        this.$message.error("受益人名称必填");
        return false;
      }
      if (!data.beneIdNum) {
        this.$message.error("证件号必填");
        return false;
      }
       if (!data.beneType) {
          this.$message.error("受益人证件类型必选");
          return false;
      }
      if (!data.beneAddress) {
        this.$message.error("受益人地址必填");
        return false;
      }
      if (!data.beneValidDateStart) {
          this.$message.error("证件开始日期必选");
          return false;
      }
      if (!data.beneValidDateEnd && beneValidDateEnd != "2999-01-01") {
          this.$message.error("证件结束日期必选");
          return false;
      }
      if (!data.beneIdNomalPic) {
          this.$message.error("受益人身份证正面照片必传");
          return false;
      }
      if (!data.beneIdReversePic) {
          this.$message.error("受益人身份证反面照片必传");
          return false;
      }
      if(data.beneValidDateEnd == '2999-01-01' && beneValidDateEnd == "forever"){
        data.beneValidDateEnd = "forever";
      }
      if (this.editIndex !== null) {
        this.tabData[this.editIndex] = data;
      } else {
        this.tabData.push(data);
      }
      this.$message.success("保存成功");
      this.overAccountShow = false;
    },
    // 身份证正面照片上传
    contactIdNomalPicSuccess(res) {
      if (res.resultStatus) {
        this.formData.beneIdNomalPic = res.resultData.fileLink;
      }
    },
     // 身份证反面照片上传
    contactIdReversePicSuccess(res) {
      if (res.resultStatus) {
        this.formData.beneIdReversePic = res.resultData.fileLink;
      }
    },
    //点击预览
    showImg(val) {
      this.showImgUrl = val;
    },
    
    // 页面跳转
    toUrl(name, bool = true) {
      this.$router.push({
        name: name,
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ;
      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
  },
  beforeDestroy() {
    if (this.$route.query.pageType != "edit") {
      let data = {
        storeDetail: this.storesApiDetail ? this.storesApiDetail.storeDetail : null,
        account: this.storesApiDetail ? this.storesApiDetail.account : null,
        config: this.storesApiDetail ? this.storesApiDetail.config : null,
        beneInfos:this.tabData,
      };
      console.log(data);
      this.setStoresApi(data);
    }
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  width: 200px;
  margin-right: 26px;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.overAccount_ul_title i{
    color: #48b8b6;
    padding-right: 3px;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 200px;}
.imgBox {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgBox img{
  max-width: 550px;
  display: block;
  margin:20px auto 0px;
}
.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
</style>
